function spinWheel(){
    const circleTab = document.querySelectorAll("[data-tab-target]") 
    const circleDataTab = document.querySelectorAll("[data-tab-content]") 

    const path = document.querySelectorAll('.policy-select-no svg path');
    // const path = document.querySelectorAll('.policy-select-no svg path');
    const pathOne = document.getElementById('Path_2909');
    const pathTwo = document.getElementById('Path_2910');
    const pathThree = document.getElementById('Path_2911');
    const pathFour = document.getElementById('Path_2912');
    const pathFive = document.getElementById('Path_2913');
    const pathSix = document.getElementById('Path_2914');
    const pathSeven = document.getElementById('Path_2915');
    const pathWrap = document.querySelector('.policy-select-wrap')
    const pathText = document.querySelector('.policy-select-no svg text')
    const pathImg  = document.querySelector('.policy-select-no svg image')
    const mainG = document.getElementById('Group_2569')
    const policyCyleStage = document.querySelector(".policy-cycle-stage");

    circleTab.forEach(tab => {
    tab.addEventListener('click', ()=>{
        const target = document.querySelector(tab.dataset.tabTarget)
        circleDataTab.forEach(tabContent => {
        tabContent.classList.remove('active')
        })
        target.classList.add('active')
        if (tab == pathOne) {     
        pathWrap.style.transform = "rotate(100deg)";
        policyCyleStage.style.transform = "rotate(-100deg)";
        //pathImg.style.transform = "translate(1087px, 429px) rotate(-102deg) scale(0.4)";      
        // pathText.style.transform="rotate(0deg)";
        } else if (tab == pathTwo) {
        // pathText.style.transform = "translate(730px, -179px) rotate(210deg)";
        pathWrap.style.transform = "rotate(150deg)";
        policyCyleStage.style.transform = "rotate(-150deg)";
        // pathText.style.transform="rotate(0deg)";
        }else if (tab == pathThree) {
        pathWrap.style.transform = "rotate(200deg)";
        policyCyleStage.style.transform = "rotate(-200deg)";
        // pathText.style.transform="rotate(0deg)";
        // pathWrap.style.transform = "translate(-50%,-50%)";
        }else if (tab == pathFour) {
        pathWrap.style.transform = "rotate(256deg)";
        policyCyleStage.style.transform = "rotate(-256deg)";
        // pathText.style.transform="rotate(0deg)";
        // pathWrap.style.transform = "translate(-50%,-50%)";
        }else if (tab == pathFive) {
        pathWrap.style.transform = "rotate(300deg)";
        policyCyleStage.style.transform = "rotate(-300deg)";
        // pathText.style.transform="rotate(0deg)";
        // pathWrap.style.transform = "translate(-50%,-50%)";
        }else if (tab == pathSix) {
        pathWrap.style.transform = "rotate(358deg)";
        policyCyleStage.style.transform = "rotate(-358deg)";
        // pathText.style.transform="rotate(0deg)";
        // pathWrap.style.transform = "translate(-50%,-50%)";
        }else if (tab == pathSeven) {
        pathWrap.style.transform = "rotate(50deg)";
        policyCyleStage.style.transform = "rotate(-50deg)";
        
        // pathText.style.transform = "rotate(322deg)";
        // pathText.style.transform = "translate(830px, 292px)";
        
        
        //pathText.style.transform="rotate(0deg)";
        // pathWrap.style.transform = "translate(-50%,-50%)";
        }

        // d = this;
        // rotate = 29.9 - (d.startAngle + d.endAngle) / 2 / Math.PI * 180;
        // mainG.transition()
        //     .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ') rotate(' + rotate + ',' + 0 + ',' + 0 + ')')
        //     .duration(1000);
        // image.transition()
        //     .attr('transform', function (dd) {
        //         return 'translate(' + labelArc.centroid(dd) + ') rotate(' + (-rotate) + ')';
        //     })
        //     .duration(1000);active = this;
        //     rotate = 29.9 - (d.startAngle + d.endAngle) / 2 / Math.PI * 180;
        //     mainG.transition()
        //         .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ') rotate(' + rotate + ',' + 0 + ',' + 0 + ')')
        //         .duration(1000);
        //     image.transition()
        //         .attr('transform', function (dd) {
        //             return 'translate(' + labelArc.centroid(dd) + ') rotate(' + (-rotate) + ')';
        //         })
        //         .duration(1000);

                // if (tab == pathOne) {     
                //     pathWrap.style.transform = "rotate(100deg)";
                //     // pathImg.style.transform = "translate(1087px, 429px) rotate(-102deg) scale(0.4)";      
                //     // pathText.style.transform="rotate(0deg)";
                //     pathTextattr('transform', 'translate(' + width / 2 + ',' + height / 2 + ') rotate(' + rotate + ',' + 0 + ',' + 0 + ')')
                // } else if (tab == pathTwo) {
                //     pathText.style.transform = "translate(730px, -179px) rotate(210deg)";
                //     pathWrap.style.transform = "rotate(150deg)";
                //     pathText.style.transform="rotate(0deg)";
                // }else if (tab == pathThree) {
                //     pathWrap.style.transform = "rotate(200deg)";
                //     // pathText.style.transform="rotate(0deg)";
                //     // pathWrap.style.transform = "translate(-50%,-50%)";
                //  }else if (tab == pathFour) {
                //     pathWrap.style.transform = "rotate(256deg)";
                //     // pathText.style.transform="rotate(0deg)";
                //     // pathWrap.style.transform = "translate(-50%,-50%)";
                //   }else if (tab == pathFive) {
                //     pathWrap.style.transform = "rotate(300deg)";
                //     // pathText.style.transform="rotate(0deg)";
                //     // pathWrap.style.transform = "translate(-50%,-50%)";
                //   }else if (tab == pathSix) {
                //     pathWrap.style.transform = "rotate(358deg)";
                //     // pathText.style.transform="rotate(0deg)";
                //     // pathWrap.style.transform = "translate(-50%,-50%)";
                //   }else if (tab == pathSeven) {
                //     pathWrap.style.transform = "rotate(50deg)";
                    
                //     // pathText.style.transform="rotate(0deg)";
                //     // pathWrap.style.transform = "translate(-50%,-50%)";
                //   }
    })
    })



    circleTab.forEach(tab => {
    console.log(path);
    tab.addEventListener('click', () => {
        console.log(tab.children[0]);    
        circleTab.forEach(item => {      
        if (item !== tab) {
            item.children[0].style.fill ="#00a2a5"   
        }   
        })
        tab.children[0].style.fill = "rgb(0, 51, 160)";    
    })
    })
}